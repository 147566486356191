//data table
import {useSortBy, useTable} from 'react-table'
import {DefaultRootState, useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {Action, Dispatch} from "redux";
import Pagination from "./Pagination";
import SkeletonCell from "./SkeletonCell";
import "../components/DataTable.css";
import {useLocation} from "react-router-dom";
import React, {ReactComponentElement, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    computePageControl,
    createUserSettingsButton,
    defaultDateParamIfNotProvided,
    deserializeObject,
    getFilterModalKeysValue,
    getPrimitiveArray,
    getShownColumns,
    getUserSettingListAll,
    serializeObject,
    splitArrayIntoChunks
} from "../helpers/utils";
import moment from "moment";
import UserSettingsModal from "./userSettingsModal/UserSettingsModal";
import {homeActions} from "../../features/home/home";
import {userSettingsAction} from "./userSettingsModal/userSettings";
import {history} from "../helpers/history";
import {quickFilterParams} from "../helpers/constants";
import ConfirmationModal from "./ConfirmationModal";

interface DataTableColumn {
    // The header text to show for this column
    Header: string | any;

    // The name of the property this column reads from the data
    accessor: string;

    // The options that specify how a user would filter by it
    FilterOptions?: FilterOptions[];

    // The Cell element, this should be JSX/TSX.
    Cell?: any;

    // The value to use when sorting - by default, uses the accessor, but this overrides that.
    overrideSortColumn?: string;

    // The customer levels that should not be able to see this column
    disallowedCustomerLevels?: string[];

    // Defaults the column to not show.
    // Usually used for either uncommonly used columns (when used in conjunction with canBeHidden) or columns that we need to search by, but not see on the table.
    defaultHidden?: boolean;

    // Lets the column be hidden/shown by user in User Properties modal
    canBeHidden?: boolean;
}

interface QuickFilterColumn {
    Label: string | any;
    // overrideFilterAccessor: string;
    // Values: any;
    keyVal: any;
    Cell?: any;
    className?: string;
    defaultGridQuery?: any;
}

interface FilterOptions {
    dependentColumns?: string[]
    onDependencyUpdated?: Function;

    FilterComponent?: ReactElement;

    //filter modal for collector or any other grid
    FilterModalOption?: FilterModalOption;

    overrideFilterLabel?: string;
    onDependencyReducer?: string;
    overrideFilterAccessor?: string;
    isHidden?: boolean

    // TODO: Implement or come up with better idea for manual manipulation of field placement
    forceWrapBefore?: boolean;
    forceWrapAfter?: boolean;
}

interface FilterModalOption {
    FilterModalTag: ReactComponentElement<any>;
    FilterModalIcon: any;
}

interface DataItem {
}

interface DataPage {
    data: DataItem[];

    hasContent: boolean;
    hasNext: boolean;
    hasPrevious: boolean;
    isFirst: boolean;
    isLast: boolean;

    totalElements: number;
    totalPages: number;

    perPage: number;
    pageNumber: number;
    size: number;
    pluralResourceName: string;
    displayingText: string;

    isSorted: boolean;
    sortColumn: string;
    sortOrder: string;
}

interface DataTableProps {
    columns: DataTableColumn[];

    quickFilters?: QuickFilterColumn[];

    //filter modal related keys
    FilterModal?: any
    FilterModalOptions?: Array<any>;

    handleClick?: boolean,
    setHandleClick?: any,

    //show user settings modal check
    showUserSettingModal?: boolean;
    userSettingsGroup?: string;

    hasBulkDelete?: boolean;
    deleteAction?: Dispatch<Action>;

    fetchAction: Dispatch<Action>;
    userSettingsCustomAction?: Dispatch<Action>;
    exportAction: any;
    selector: (state: DefaultRootState) => any;
    getTeamUserSettings?: (state: DefaultRootState) => any;
    defaultSortField?: string;
    defaultSortAscending?: boolean;
    defaultPageSize?: number;
    formCheckForModals?: boolean;
    appendUrl: boolean;
    setFlowDisable?: boolean;
    hidePagination?: boolean;
    customFilter?: any;
    defaultFilter?: any;
    preDefaultFilter?: any;
    isDetailPageGrid?: boolean;
    hasCreateFormBtn?: any;
    chosenSelectorFilter?: any;
    shouldHideGrid?: boolean;
    onRowClicked?: any;
    forceRender?: boolean;
    isMultiSelectModal?: boolean;
    isSearchApplied?: boolean;
    preSelectedIds?:Array<any>;
}


const getPageData = (dispatch: Function, fetchAction: Dispatch<Action>, filterQuery: any, pageNumber: number, pageSize: number, sort: any, defaultPageSize: number | undefined, setHideSkeleton: any, shouldHideGrid: any, locations: any, appendUrl: boolean, customQuery: any, applyFilterByDefault: any) => {

    let query = {
        ...filterQuery,
        pageNumber: pageNumber,
        pageSize: !pageSize ? defaultPageSize : (pageSize === 0 ? 15 : pageSize),
    }

    if (appendUrl) {
        let tempObj = {...filterQuery, pageNumber: pageNumber + 1};
        if (pageNumber === 0) {
            delete tempObj['pageNumber'];
        }
        history.push({
            pathname: locations.pathname.includes("external") === false ? locations.pathname.slice(1) : locations.pathname,
            search: serializeObject(tempObj),
            state: {detail: tempObj}
        });
    }

    if (shouldHideGrid === false && applyFilterByDefault == true) {
        if (customQuery !== null && customQuery !== undefined) {
            dispatch(fetchAction({...customQuery, ...query}));
        } else {
            dispatch(fetchAction(query));
        }
    }

    setHideSkeleton(true);
}

const exportData = (dispatch: Function, exportAction: Dispatch<Action>, filterQuery: any, pageNumber: number, pageSize: number, sort: any, setExportBtn: any, userSettingsGroup: String, customQuery: any) => {
    let sortOrder = sort.sortAscending ? "ASCENDING" : "DESCENDING";

    let query = {
        ...filterQuery,
        pageNumber: pageNumber,
        pageSize: 10000,
        sort: sort.sortColumn,
        sortOrder: sortOrder,
        setExportBtn: setExportBtn
    }

    if (userSettingsGroup === "Report") {
        if (customQuery && customQuery.period !== undefined && customQuery.period === 'DAYS' && (filterQuery.createdDateFrom === undefined || filterQuery.createdDateTo === undefined)) {
            dispatch(exportAction({...customQuery, ...defaultDateParamIfNotProvided(query)}));
        } else {
            dispatch(exportAction({...customQuery, ...query}));
        }
    } else {
        dispatch(exportAction(query));
    }


}

const updateFilterQuery = (filterKey: string, value: any, filterQuery: any, setFilterQuery: Function, isQuickFilter: boolean = false) => {

    const isCheckbox = value?.target?.type == 'checkbox';
    const isDate = value?.target?.type == undefined && (filterKey === 'createdDateTo' || filterKey === 'createdDateFrom' || filterKey === 'paidDateFrom' || filterKey === 'paidDateTo' || filterKey === 'eventDateFrom' || filterKey === 'eventDateTo' || filterKey === 'deliveredDateFrom' || filterKey === 'deliveredDateTo' || filterKey === 'creationDateFrom' || filterKey === 'creationDateTo' || filterKey === 'lastCheckedDateFrom' || filterKey === 'lastCheckedDateTo' || filterKey === 'productDeliveredDateFrom' || filterKey === 'productDeliveredDateTo' || filterKey === 'transmitDateFrom' || filterKey === 'transmitDateTo' || filterKey === 'asOfDateFrom' || filterKey === 'asOfDateTo' || filterKey === 'activationDateFrom'|| filterKey === 'activationDateTo');
    const isPhoneNo = value?.target?.placeholder === 'Enter Phone...';
    const date = moment(value).format("yyyy-MM-DD HH:mm:ss").toUpperCase()
    value =
        // Checkbox
        (isCheckbox ? value !== undefined && value?.target?.checked : null) ??
        // Date
        (isDate ? date : null) ??
        //unformatted Phone
        (isPhoneNo ? value?.target?.value : null) ??
        // Anything else that has a native onChange
        value?.target?.value ??
        // Raw values sent over
        value;

    let tempQuery: any = {...filterQuery};
    if (value === undefined || value === '' || (Array.isArray(value) && !value.length)) {
        delete tempQuery[filterKey];
    } else {
        if (value == "INVALID DATE") {
            delete tempQuery[filterKey];
        } else {
            tempQuery[filterKey] = value;
        }
    }

    if (!isQuickFilter) {
        delete tempQuery['quickFilters'];
    }

    if (filterKey === 'resetQuickFilter') {
        Object.keys(cur => {
            delete tempQuery[cur];
        });
        let tempQuery: any = {...filterQuery};
        setFilterQuery(tempQuery);
    } else {
        setFilterQuery(tempQuery);
    }


};

export default function DataTable(props: DataTableProps) {
    const dispatch = useDispatch();
    const location: any = useLocation();
    const storageData = useSelector((state: any) => state.utilDataReducer);

    const searchParams = new URLSearchParams(location.search);
    const pageNum: number = parseInt(searchParams.get('pageNumber'));

    const {
        columns,
        quickFilters,
        FilterModal,
        FilterModalOptions,
        handleClick,
        setHandleClick,

        userSettingsCustomAction,
        //show user settings modal check
        showUserSettingModal,
        userSettingsGroup,
        hasBulkDelete,
        deleteAction,
        fetchAction,
        exportAction,
        selector,
        getTeamUserSettings,
        defaultSortField,
        defaultSortAscending,
        defaultPageSize = 15,
        formCheckForModals,
        hidePagination,
        customFilter,
        defaultFilter,
        preDefaultFilter,
        isDetailPageGrid,
        hasCreateFormBtn,
        chosenSelectorFilter,
        appendUrl = false,
        shouldHideGrid = false,
        isSearchApplied = true,
        onRowClicked,
        isMultiSelectModal = false,
        preSelectedIds = []
    } = props;

    const reducerState = useSelector(selector);
    const userSettingsData = useSelector(getTeamUserSettings ?? ((state: any) => state.findUserSettingsByGroupNameReducer));

    let page: DataPage = reducerState ? reducerState.items : null;
    let data: DataItem[] = page ? page.data : [];

    let defaultSort = !defaultSortField ? 'creationDate' : defaultSortField;
    let defaultSortOrderAscending = defaultSortAscending !== undefined && defaultSortAscending;

    // If this data doesn't have pagination, "page" is actually the data.
    if (data == null && page && Array.isArray(page)) {
        data = page;
    }

    let displayText = page !== undefined ? page.displayingText : null;

    const pageCount = page ? page.totalPages : null;

    const [sort, setSort] = useState({
        sortColumn: defaultSort,
        isSorted: true,
        sortAscending: defaultSortOrderAscending
    }); // TODO: Add default sort options

    const [pageNumber, setPageNumber] = useState(pageNum ? pageNum - 1 : 0);
    const [pageSize, setPageSize] = useState(defaultPageSize ?? 15);

    let query = {};

    const [filterQuery, setFilterQuery] = useState(customFilter ? Object.assign(query, customFilter) : {
        sort: sort.sortColumn,
        sortOrder: sort.sortAscending ? "ASCENDING" : "DESCENDING"
    });

    const [tempQuery, setTempQuery] = useState(customFilter ? Object.assign(query, customFilter) : {});

    const [deleteItemList, setDeleteItemList] = useState([]);
    const [render, setRender] = useState("");

    useEffect(() => {
        if (preDefaultFilter !== undefined && preDefaultFilter.preDefaultCheck === true) {
            if (preDefaultFilter.preDefaultFilterHalt) {
                setFilterQuery(preDefaultFilter ? Object.assign(query, preDefaultFilter) : {});
                preDefaultFilter.setPreDefaultFilterHalt(false)
                setPageSize(defaultPageSize);
                setRender("reload" + Date.now());
            }

            setTempQuery(preDefaultFilter ? Object.assign(query, preDefaultFilter) : {});
            preDefaultFilter.setPreDefaultCheck(false);
        }
    }, [preDefaultFilter]);

    const refFilterQuery = useRef(tempQuery);


    useEffect(() => {
        refFilterQuery.current = tempQuery;
    }, [tempQuery]);

    const [applyFilter, setApplyFilter] = useState(false);
    const [applyFilterByDefault, setApplyFilterByDefault] = useState(isSearchApplied);

    const [applyExport, setApplyExport] = useState(false);

    //state to reset form values
    const [applyReset, setApplyReset] = useState(false);

    const isLoadingUserSettings = useMemo(
        () =>
            (showUserSettingModal && (userSettingsData === null || userSettingsData.items === undefined)),
        [userSettingsData]);


    const shouldBeLoading = useMemo(
        () =>
            (reducerState == null || page == null || data == null || isLoadingUserSettings),
        [reducerState, isLoadingUserSettings]);


    const [disable, setDisable] = useState(false);

    const [disableDelete, setDisableDelete] = useState(false);

    const [clear, setClear] = useState(false);

    const [exportBtn, setExportBtn] = useState(false);

    //configure user grid state
    const [showUserSettings, setShowUserSettings] = useState(false);
    const [showToggleDeleteConfirmation, setShowToggleDeleteConfirmation] = useState(false);

    const [loading, setLoading] = useState(shouldBeLoading);

    const [hideSkeleton, setHideSkeleton] = useState(false);
    const [readyToUseModalFilter, setReadyToUseModalFilter] = useState(false);

    useEffect(() => {
        if (reducerState.loading) {
            setHideSkeleton(false)
        }
    }, [reducerState]);

    useEffect(() => {
        setLoading(shouldBeLoading);
    }, [data, isLoadingUserSettings]);

    useEffect(handleTempQuery, []);

    function handleTempQuery() {
        const combinedFilters = query;
        if (customFilter) {
            Object.assign(combinedFilters, customFilter);
        }

        if (preDefaultFilter) {
            Object.assign(combinedFilters, preDefaultFilter);
        }

        if (defaultFilter) {
            Object.assign(combinedFilters, defaultFilter);
        }

        // Apply combined filters to query
        setTempQuery(combinedFilters);

        // Set ready flag
        setReadyToUseModalFilter(true);
    }

    useEffect(handleUserSettings, []);

    function handleUserSettings() {
        if (userSettingsGroup !== undefined && showUserSettingModal) {
            let userSettingQuery = {
                ...filterQuery,
                userSettingsGroup: userSettingsGroup
            }

            userSettingsCustomAction ?
                dispatch(userSettingsCustomAction(userSettingQuery)) :
                dispatch(userSettingsAction.findUserSettingsByGroupNameActions(userSettingsGroup))
        }

        return () => {
            if (userSettingsGroup !== undefined && showUserSettingModal) {
                dispatch(homeActions.resetReducerAction("findUserSettingsByGroupNameReducer"));
            }

            dispatch(homeActions.resetReducerAction(selector.toString().split('.', 2)[1].replaceAll("}", "")));
        }
    }

    useEffect(handleFilterModalOptions, [FilterModalOptions]);

    function handleFilterModalOptions() {
        if ((preDefaultFilter === undefined || !preDefaultFilter.preDefaultCheck) && readyToUseModalFilter) {

            let tempQueryToSet: any = {...tempQuery};
            FilterModalOptions !== undefined && FilterModalOptions.map((cur) => {

                let showModalValue: string = cur.showModalValue;
                let showModalTagValue: string = cur.showModalTagValue;
                let accessorKey: string | undefined = cur.filterModalKeyAccessor;

                if (cur.showModalValue !== undefined && cur.showModalTagValue !== undefined) {
                    if (accessorKey) {
                        tempQueryToSet[accessorKey] = showModalValue;
                        tempQueryToSet[accessorKey + "ShowValue"] = showModalTagValue;
                    }
                }
            })

            setTempQuery(_.pickBy(tempQueryToSet));
        }
    }

    useEffect(handleLoadingPageSize, [isLoadingUserSettings]);

    function handleLoadingPageSize() {
        if (showUserSettingModal) {
            //this needed to be done in order to hit search api when pageSize remains same in userSettings config .else we can maintain a check for that.
            setPageSize(0);
        }

        if (userSettingsData?.items && showUserSettingModal && !isLoadingUserSettings) {
            const pageSizeSetting = parseInt(userSettingsData?.items?.find(cur => cur.key === "pageNumber")?.value);
            setPageSize(pageSizeSetting ?? defaultPageSize ?? 15);
        }
    }

    //readyToHitSearchApi is true when config filtering is enabled.
    let readyToHitSearchApi = showUserSettingModal &&
    !isLoadingUserSettings &&
    pageSize !== 0 &&
    userSettingsData?.items?.length === 0 ?
        true :
        userSettingsData?.items?.length > 0 &&
        userSettingsData.items.filter(cur => cur.group === userSettingsGroup).length > 0;

    useEffect(() => {
        if (!location.search && !isLoadingUserSettings && (preDefaultFilter === undefined ? true : !preDefaultFilter.preDefaultFilterHalt)) {

            filterQuery.sort = sort.sortColumn;
            filterQuery.sortOrder = sort.sortAscending ? "ASCENDING" : "DESCENDING";

            //readyToHitSearchApi is true when config filtering is enabled.
            readyToHitSearchApi && getPageData(dispatch, fetchAction, filterQuery, pageNumber, pageSize, sort, defaultPageSize, setHideSkeleton, shouldHideGrid, location, appendUrl, customFilter, applyFilterByDefault);

            //readyToHitSearchApi is true when config filtering is not enabled.
            !showUserSettingModal && !isLoadingUserSettings && getPageData(dispatch, fetchAction, filterQuery, pageNumber, pageSize, sort, defaultPageSize, setHideSkeleton, shouldHideGrid, location, appendUrl, customFilter, applyFilterByDefault);
        }

        if (location.search && (deserializeObject(location.search) instanceof Object)) {

            let obj = deserializeObject(location.search);

            if (appendUrl) {
                // Add sort and sortOrder properties
                obj.sort = sort.sortColumn;
                obj.sortOrder = sort.sortAscending ? "ASCENDING" : "DESCENDING";

                setFilterQuery(obj);
                setTempQuery(obj);
            } else {
                filterQuery.sort = sort.sortColumn;
                filterQuery.sortOrder = sort.sortAscending ? "ASCENDING" : "DESCENDING";
            }

            readyToHitSearchApi && getPageData(dispatch, fetchAction, appendUrl === true ? obj : filterQuery, pageNumber, pageSize, sort, defaultPageSize, setHideSkeleton, shouldHideGrid, location, appendUrl, customFilter, applyFilterByDefault);
            !showUserSettingModal && !isLoadingUserSettings && getPageData(dispatch, fetchAction, appendUrl === true ? obj : filterQuery, pageNumber, pageSize, sort, defaultPageSize, setHideSkeleton, shouldHideGrid, location, appendUrl, customFilter, applyFilterByDefault);
        }

    }, [pageNumber, pageSize, sort, render]);

    // TODO: Handles clicks on rows... make this handled better
    useEffect(() => {
        if (handleClick) {
            setApplyFilter(true);
            setHandleClick(false);
        }
    }, [handleClick])

    useEffect(() => {
        if (applyFilter) {

            tempQuery.sort = sort.sortColumn;
            tempQuery.sortOrder = sort.sortAscending ? "ASCENDING" : "DESCENDING";

            setFilterQuery(tempQuery);
            setPageNumber(0);

            getPageData(dispatch,
                fetchAction,
                tempQuery,
                0,
                pageSize,
                sort,
                defaultPageSize,
                setHideSkeleton,
                shouldHideGrid,
                location,
                appendUrl,
                customFilter,
                applyFilterByDefault);

            setApplyFilter(false);
        }
    }, [applyFilter]);


    function handleToggleDeleteSubmit() {
        if (hasBulkDelete && deleteAction !== null) {

            setDisableDelete(true);

            let sortOrder = sort.sortAscending ? "ASCENDING" : "DESCENDING";

            let query = {
                ...filterQuery,
                pageNumber: pageNumber,
                pageSize: !defaultPageSize ? (!pageSize || pageSize === 0) ? 15 : pageSize : defaultPageSize,
                sort: sort.sortColumn,
                sortOrder: sortOrder,
                setShowBulkDeleteConfirmation: setShowToggleDeleteConfirmation,
                deleteList: deleteItemList,
                setDeleteItemList: setDeleteItemList,
                setDisableDelete: setDisableDelete
            }

            dispatch(deleteAction(query));
        }
    }

    useEffect(() => {
        if (applyExport) {
            setFilterQuery(tempQuery);
            exportData(dispatch, exportAction, tempQuery, pageNumber, pageSize, sort, setExportBtn, userSettingsGroup, customFilter);
            setApplyExport(false);
        }
    }, [applyExport]);


    let tableColumns = useMemo(
        () =>
            (loading || isLoadingUserSettings)
                ? columns.map((column) => ({
                    ...column,
                    Cell: <SkeletonCell/>,
                }))
                :
                getShownColumns(hasBulkDelete ? columns : columns.filter(cur => cur.Header !== "Bulk Delete"), showUserSettingModal === true ? userSettingsData.items && Array.isArray(userSettingsData.items) && userSettingsData.items : []),
        [loading, hasBulkDelete, columns, isLoadingUserSettings]
    );

    let tableData = useMemo(
        () => (loading ?
                Array<any>(!pageSize || pageSize === 0 ? 15 : pageSize).fill({}) :
                data
        ),
        [loading, data]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns: tableColumns,
            data: tableData,
            manualSortBy: true,
            disableMultiSort: true,
            defaultCanSort: true,
            autoResetSortBy: false,
            initialState: {sortBy: [{id: defaultSort, desc: !defaultSortOrderAscending}]},
        },
        useSortBy)

    const handleSort = useCallback(
        (column) => {

            console.log("column", column);

            if (!column.canSort) {
                return;
            }

            if (column.isSorted && !column.isSortedDesc) {
                column.clearSortBy();
            } else {
                column.toggleSortBy(!column.isSortedDesc, false);
            }

            setSort({
                sortColumn: column.overrideSortColumn ? column.overrideSortColumn : column.id,
                isSorted: column.isSorted,
                sortAscending: !column.isSortedDesc
            });

        }, []);

    useEffect(() => {
        if (applyReset) {
            chosenSelectorFilter !== undefined && Array.isArray(chosenSelectorFilter) && chosenSelectorFilter.map((cur) => {
                cur?.setToDefault && cur.setToDefault(cur.defaultValue);
            })

            deleteItemList?.splice(0, deleteItemList.length);

            FilterModalOptions?.map(cur => {
                cur.setShowModalValue("");
                cur.setShowModalTagValue("");
            });

            if (location.search && location.search !== '') {
                history.push({
                    pathname: location.pathname.slice(1),
                    search: '',
                    state: {detail: null}
                })
            }

            setFilterQuery(customFilter ? Object.assign(query, customFilter) : {});
            setTempQuery(customFilter ? Object.assign(query, customFilter) : {});
            setApplyReset(false);
            setSort({
                sortColumn: defaultSort,
                isSorted: true,
                sortAscending: defaultSortOrderAscending
            });

            for (let column of columns) {

                for (let filterOptions of column.FilterOptions) {
                    if (!filterOptions.FilterComponent?.props?.reset) continue;
                    filterOptions.FilterComponent.props.reset();

                    if (filterOptions?.onDependencyUpdated) {
                        let resetReducer = filterOptions?.onDependencyReducer;
                        resetReducer && dispatch(homeActions.resetReducerAction(resetReducer));
                    }
                }
            }

            shouldHideGrid && setClear(false);

            setApplyFilter(true);
        }
    }, [applyReset]);

    useEffect(() => {
        if (reducerState.loading === false) {
            setDisable(false);
            setClear(false);
        }
    }, [data]);

    for (let column of columns) {

        column.defaultHidden = !!column.defaultHidden

        if (!column.FilterOptions) {
            column.FilterOptions = [];
        }

        if (!column.disallowedCustomerLevels) {
            column.disallowedCustomerLevels = [];
        }

        let header = getHeaderByName(headerGroups, column.Header);
        if (header) {
            if (storageData?.items !== null && storageData?.items !== undefined && storageData?.items?.customerLevel && header.isVisible) {
                if (shouldHideFromUser(column.disallowedCustomerLevels, storageData?.items?.customerLevel)) {
                    header.toggleHidden();
                }
            }
            if (
                column.defaultHidden &&
                !column.canBeHidden
            ) {
                header.toggleHidden();
            } else if (
                (!userSettingsData.items || userSettingsData.items.length === 0 || userSettingsData.items.every(item => item.key !== column.Header)) &&
                column.defaultHidden &&
                column.canBeHidden
            ) {
                header.toggleHidden();
            }
        }

        for (let filterOptions of column.FilterOptions) {

            if (filterOptions.FilterModalOption?.FilterModalTag) {
                filterOptions.FilterModalOption.FilterModalTag = React.cloneElement(
                    filterOptions.FilterModalOption.FilterModalTag,
                    {
                        onKeyPress: (event: KeyboardEvent) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                setApplyFilter(true);
                                return false;
                            }
                        },
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            updateFilterQuery(
                                filterOptions.overrideFilterAccessor || column.accessor,
                                event.target.value,
                                tempQuery,
                                setTempQuery
                            );
                        }
                    }
                );
            }

            if (filterOptions.FilterComponent) {

                filterOptions.FilterComponent = React.cloneElement(
                    filterOptions.FilterComponent,
                    {
                        onKeyPress: (event: KeyboardEvent) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                setApplyFilter(true);
                                return false;
                            }
                        },
                        onChange: (event: any) => {
                            updateFilterQuery(
                                filterOptions.overrideFilterAccessor || column.accessor,
                                event,
                                refFilterQuery.current,
                                setTempQuery
                            );
                        }
                    }
                );
            }

            bindDependents(column, tableColumns, refFilterQuery, setTempQuery);
        }

    }

    let tableColumnsToShow = storageData?.items ? columns.filter((c: DataTableColumn) => !(c && c.disallowedCustomerLevels && storageData?.items?.customerLevel &&
        shouldHideFromUser(c.disallowedCustomerLevels, storageData?.items?.customerLevel))) : columns;

    let filterFieldsToShow = Array.isArray(tableColumnsToShow) && tableColumnsToShow.filter((c: DataTableColumn) => c && c.FilterOptions && Object.keys(c.FilterOptions).length);

    // TODO: WHY 4?!
    let hasMoreThanFourFilterFields = filterFieldsToShow.length > 4;

    let filterFieldRows: DataTableColumn[][] = Array.isArray(filterFieldsToShow) && splitArrayIntoChunks(filterFieldsToShow,
        hasMoreThanFourFilterFields ? 3 : 2);

    let columnWidthClass = 'col-md-';
    let columnWidth = hasMoreThanFourFilterFields ? 4 : 6;

    useEffect(() => {
        if (chosenSelectorFilter && Array.isArray(chosenSelectorFilter) && chosenSelectorFilter.length > 0 && chosenSelectorFilter[0].chosenSelectorAccessor !== undefined) {
            let tempQueryToSet: any = {...tempQuery};
            chosenSelectorFilter.map((cur) => {
                let key = cur.chosenSelectorAccessor;
                let value = getPrimitiveArray(cur.value, "value");
                if (Array.isArray(value) && value.length > 0) {
                    tempQueryToSet[key] = value;
                } else {
                    delete tempQueryToSet[key];
                }
            })
            setTempQuery(_.pickBy(tempQueryToSet));
        }

    }, [chosenSelectorFilter])

    const debugLogging = <>
        <span>{"ApplyExport: " + JSON.stringify(applyExport, null, 4)}</span><br/>
        <span>{"ApplyReset: " + JSON.stringify(applyReset, null, 4)}</span><br/>
        <span>{"ApplyFilter: " + JSON.stringify(applyFilter, null, 4)}</span><br/>
        <span>{"FilterQuery: " + JSON.stringify(filterQuery, null, 4)}</span><br/>
        <span>{"tempQuery: " + JSON.stringify(tempQuery, null, 4)}</span><br/>
        <span>{"Loading: " + !!reducerState?.loading}</span><br/>
        <span>{"FilterField Count Count: " + filterFieldsToShow?.length}</span><br/>
        <span>{"FilterFieldRow Count: " + filterFieldRows?.length}</span><br/>
    </>

    const [selectedRowId, setSelectedRowId] = useState("");

    const [selectedRowsIds, setSelectedRowsIds] = useState([]);

    useEffect(() => {
        if(preSelectedIds  && preSelectedIds.length > 0){
            setSelectedRowsIds(preSelectedIds);
        }

    }, [FilterModalOptions])
    const getClassName = (row) => {
        if (selectedRowsIds.length > 0) {
            return selectedRowsIds.includes(row.original.id) ? 'selected_row cursor' : 'cursor';
        } else {
            return selectedRowId === row.original.id ? 'selected_row cursor' : (onRowClicked !== undefined ? 'cursor' : '');
        }
    };
    return (
        <>
            {/*{debugLogging}*/}

            <div className="">

                {quickFilters && Array.isArray(quickFilters) && quickFilters.length > 0 &&
                    <div className={"d-flex mb-1"}>
                        {quickFilters.map((cur,index) => {
                            //this static quickFilter key will remain same for all the quick filters it is the key of search param value, and it will change acc. to filter type

                            return (
                                <>
                                    <div title={cur?.Label} key={index}
                                         className={filterQuery.hasOwnProperty(quickFilterParams?.quickFilterKey) && filterQuery[quickFilterParams?.quickFilterKey] === cur.className && _.isEqual(filterQuery[Object.keys(cur.keyVal)[0]], cur.keyVal[Object.keys(cur.keyVal)[0]]) ? cur?.className + " inline  active_tabs cursor" : cur?.className + " inline cursor"}
                                         onClick={(e) => {
                                             if (!filterQuery.hasOwnProperty(quickFilterParams?.quickFilterKey) || (filterQuery.hasOwnProperty(quickFilterParams?.quickFilterKey) && filterQuery[quickFilterParams?.quickFilterKey] !== cur.className)) {
                                                 setFilterQuery(cur.keyVal);
                                                 setTempQuery(cur.keyVal);
                                                 getPageData(dispatch, fetchAction, cur.keyVal, pageNumber, pageSize, sort, defaultPageSize, setHideSkeleton, shouldHideGrid, location, appendUrl, customFilter, applyFilterByDefault);
                                             } else {
                                                 setFilterQuery(quickFilters[0]?.defaultGridQuery || {});
                                                 setTempQuery(quickFilters[0]?.defaultGridQuery || {});
                                                 getPageData(dispatch, fetchAction, quickFilters[0]?.defaultGridQuery || {}, pageNumber, pageSize, sort, defaultPageSize, setHideSkeleton, shouldHideGrid, location, appendUrl, customFilter, applyFilterByDefault);
                                             }
                                         }}> {cur?.Cell}</div>
                                </>
                            )
                        })

                        }

                    </div>
                }


                {filterFieldsToShow && filterFieldsToShow.length > 0 &&
                    <form>
                        <div
                            style={{
                                marginBottom: formCheckForModals ? "" : 10,
                                border: formCheckForModals ? "" : "2px solid steelblue",
                                display: formCheckForModals ? 'block' : 'none'
                            }}
                            className="container-fluid order_form pb-2 pt-2" id="grid">

                            {filterFieldRows.map((filterFieldRow: DataTableColumn[]) => (
                                <div className="form-row">
                                    {
                                        filterFieldRow.map((filterField: DataTableColumn) => filterField && filterField.FilterOptions && filterField.FilterOptions.length &&
                                            (
                                                <div
                                                    className={formCheckForModals === true && filterFieldsToShow.length === 1 ? "form-group col-md-12" : "form-group " + columnWidthClass + columnWidth}
                                                    style={{display: "flex", justifyContent: "space-between"}}>
                                                    {
                                                        filterField.FilterOptions.map((filterOptions: FilterOptions) => Object.keys(filterOptions).length &&
                                                            (
                                                                <div className="form-field" style={{width: "inherit"}}>
                                                                    <label>
                                                                        {filterOptions?.isHidden == true ? '' : (filterOptions.overrideFilterLabel || filterField.Header)}
                                                                    </label>

                                                                    {filterOptions.FilterModalOption?.FilterModalIcon}
                                                                    {filterOptions.FilterComponent || filterOptions.FilterModalOption?.FilterModalTag}
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            ))}

                            <div className="form-row">

                                {!formCheckForModals && exportAction !== null && exportAction !== false && (exportBtn === true ?
                                    <button disabled={true} className="cm_btn btn_success_spinner">
                                        <>
                                            {"Exporting..."}
                                            <span className="spinner"/>
                                        </>
                                    </button> : <button type={'button'} onClick={(e) => {
                                        e.preventDefault();
                                        setApplyExport(true)
                                        setExportBtn(true)
                                    }} className="cm_btn ml-0">Export</button>)
                                }


                                {!formCheckForModals && (clear === true ?
                                    <button disabled={true} className="cm_btn cm_bg btn_success_spinner">
                                        <>
                                            {"Clear..."}
                                            <span className="spinner"/>
                                        </>
                                    </button> : <button type={'button'} onClick={(e) => {
                                        e.preventDefault();
                                        preDefaultFilter && preDefaultFilter.setProperty && preDefaultFilter.setProperty("");
                                        setApplyReset(true)
                                        setClear(true)
                                    }} className="cm_btn cm_bg ml-0">Clear</button>)
                                }

                                {!formCheckForModals && shouldHideGrid == false && (disable === true ?
                                    <button disabled={true} className="cm_btn btn_success_spinner">
                                        <>
                                            {"Apply..."}
                                            <span className="spinner"/>
                                        </>
                                    </button> : <button onClick={(e) => {
                                        setDeleteItemList([]);
                                        e.preventDefault();
                                        delete tempQuery.pageNumber;
                                        setApplyFilterByDefault(true);
                                        setApplyFilter(true)
                                        setDisable(true)
                                    }} className="cm_btn ml-0">Apply</button>)
                                }

                                {hasBulkDelete && (deleteItemList.length === 0 || ((tempQuery?.deleted === true && filterQuery?.deleted === false) || (tempQuery?.deleted === false && filterQuery?.deleted === true)) ?

                                        <button disabled={true} className="cm_btn disabled_button">
                                            {tempQuery?.deleted === true ? 'Restore' : 'Delete'}
                                        </button> :
                                        <button type={'button'} onClick={(e) => {
                                            e.preventDefault();
                                            setShowToggleDeleteConfirmation(true);
                                        }}
                                                className="cm_btn ml-0">{tempQuery?.deleted === true && filterQuery?.deleted === true ? 'Restore' : 'Delete'}
                                        </button>
                                )}

                            </div>
                        </div>

                    </form>
                }


                {shouldHideGrid == false && <div>

                    {!reducerState.loading && reducerState.items && !isLoadingUserSettings && showUserSettingModal !== undefined && showUserSettingModal && userSettingsData.items && computePageControl(userSettingsData.items, "TOP") &&
                        createUserSettingsButton("Configure " + location !== undefined ? location.pathname.slice(1).charAt(0).toUpperCase() + location.pathname.slice(2) : "-" + " Grid", showUserSettings, setShowUserSettings, reducerState.items.data)}

                    {!loading && !reducerState.loading && (!hidePagination && pageCount != null && pageCount > 0) && userSettingsData.items && computePageControl(userSettingsData.items, "TOP") &&
                        <Pagination
                            displayText={displayText}
                            pageCount={pageCount}
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                            pageSize={reducerState.items ? reducerState.items.size : pageSize}
                            userSettingsData={userSettingsGroup !== null && userSettingsGroup !== undefined ? userSettingsData.items && userSettingsData.items.length === 0 ? getUserSettingListAll([], userSettingsGroup, 15) : userSettingsData.items : null}
                            appendUrl={appendUrl}
                        />
                    }

                    {applyFilterByDefault == true && <div className={"table_wrapper"}>
                        <div className={"table-responsive"}>
                            <table {...getTableProps()}
                                   className={isDetailPageGrid ? "table table-hover" : "table table-hover table-striped"}>
                                <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {hasBulkDelete && <th role="columnheader"></th>}
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                onClick={() => handleSort(column)}
                                                className={userSettingsGroup === 'Order' ? "table_sticky_header" : ""}>

                                                {column.render('Header')}

                                                {/* Add a sort direction indicator */}
                                                <span className="noselect">
                                    &ensp;<i
                                                    className={"cursor fas " + (column.canSort ? column.isSorted ? column.isSortedDesc ? 'fa-sort-down' : 'fa-sort-up' : 'fa-sort' : '')}/>
                                    </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>

                                <tbody {...getTableBodyProps()}>
                                {!hideSkeleton && rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}
                                            onClick={() => {
                                                if (onRowClicked != undefined && isMultiSelectModal == true) {
                                                    // Multi-select case
                                                    onRowClicked(selectedRowsIds,setSelectedRowsIds,row);  // Handle adding/removing from selectedRowsIds
                                                } else {
                                                    // Single-select case
                                                    if (onRowClicked != undefined) {
                                                        onRowClicked(setSelectedRowId, row);
                                                    }
                                                }
                                            }}
                                            className={getClassName(row)}
                                        >
                                            {hasBulkDelete &&
                                                (reducerState.loading || reducerState.items?.error !== undefined ?
                                                        <td role={"cell"}><SkeletonCell/></td> :
                                                        <td role="cell">
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    if (deleteItemList.length !== 0) {
                                                                        const result = deleteItemList.includes(row.original.id);
                                                                        if (result) {
                                                                            const k = deleteItemList.indexOf(row.original.id);
                                                                            if (k > -1) {
                                                                                deleteItemList.splice(k, 1);
                                                                            }
                                                                        } else {
                                                                            deleteItemList.push(row.original.id);
                                                                        }
                                                                    } else {
                                                                        deleteItemList.push(row.original.id);
                                                                    }
                                                                    setRender(row.original.id + Date.now());
                                                                }}
                                                                checked={deleteItemList.includes(row.original.id)}
                                                            />
                                                        </td>
                                                )}
                                            {row.cells.map(cell => {
                                                return (
                                                    <td  {...cell.getCellProps()}>
                                                        {reducerState.loading ? <SkeletonCell/>
                                                            : cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })
                                }
                                {columns.length > 1 && columns[1].Header === "Permissions" ? "" :
                                    page && page.data && page.data.length === 0 &&
                                    <tr>
                                        <td colSpan={16}>{displayText}
                                            {formCheckForModals === true && hasCreateFormBtn && Object.keys(hasCreateFormBtn).length > 0 &&
                                                <span className="ml-2">
                                        <a className="column-link active cursor" target="_blank"
                                           href={hasCreateFormBtn.path}>{hasCreateFormBtn.label}</a>
                                    </span>
                                            }

                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>}

                    {!reducerState.loading && reducerState.items && !isLoadingUserSettings && showUserSettingModal !== undefined && showUserSettingModal && userSettingsData.items && computePageControl(userSettingsData.items, "BOTTOM") &&
                        createUserSettingsButton("Configure " + location !== undefined ? location.pathname.slice(1).charAt(0).toUpperCase() + location.pathname.slice(2) : "-" + " Grid", showUserSettings, setShowUserSettings, reducerState.items.data)}

                    {(!loading && !reducerState.loading && (!hidePagination && pageCount != null && pageCount > 0) && userSettingsData.items && computePageControl(userSettingsData.items, "BOTTOM") || showUserSettingModal === undefined || showUserSettingModal === false) &&
                        <Pagination
                            displayText={displayText}
                            pageCount={pageCount}
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                            pageSize={reducerState.items ? reducerState.items.size : pageSize}
                            userSettingsData={userSettingsGroup !== null && userSettingsGroup !== undefined ? userSettingsData.items && userSettingsData.items.length === 0 ? getUserSettingListAll([], userSettingsGroup, 15) : userSettingsData.items : null}
                            appendUrl={appendUrl}
                        />
                    }

                    {FilterModalOptions && Array.isArray(FilterModalOptions) && getFilterModalKeysValue(FilterModalOptions, "showModal")
                        && <FilterModal show={getFilterModalKeysValue(FilterModalOptions, "showModal")}
                                        setShow={getFilterModalKeysValue(FilterModalOptions, "setShowModal")}
                                        setValue={getFilterModalKeysValue(FilterModalOptions, "setShowModalValue")}
                                        setModalShowValue={getFilterModalKeysValue(FilterModalOptions, "setShowModalTagValue")}
                                        FilterModalKey={getFilterModalKeysValue(FilterModalOptions, "filterModalKey")}
                                        isMultiSelect={getFilterModalKeysValue(FilterModalOptions, "isMultiSelect")}
                                        value={getFilterModalKeysValue(FilterModalOptions, "showModalValue")}
                                        modalShowValue={getFilterModalKeysValue(FilterModalOptions, "showModalTagValue")}

                        />}


                    {showUserSettings &&
                        <UserSettingsModal
                            gridName={userSettingsGroup}
                            selector={getTeamUserSettings ? getTeamUserSettings : "findUserSettingsByGroupNameReducer"}
                            headerGroups={columns.filter(cur => (
                                cur.defaultHidden === false ||
                                (cur.defaultHidden === true && (cur.canBeHidden === true || cur.canBeHidden === false))
                            ))}
                            show={showUserSettings}
                            setShow={setShowUserSettings}
                        />
                    }

                    {showToggleDeleteConfirmation &&
                        <ConfirmationModal
                            title={tempQuery?.deleted === true && filterQuery?.deleted === true ? 'Bulk Restore Confirmation' : "Bulk Delete Confirmation"}
                            body={tempQuery?.deleted === true ? 'Are you sure you want to restore all selected items?' : "Are you sure you want to delete all selected items?"}
                            action={handleToggleDeleteSubmit}
                            show={showToggleDeleteConfirmation}
                            setShow={setShowToggleDeleteConfirmation}
                            disable={disableDelete}></ConfirmationModal>
                    }

                </div>}

            </div>
        </>
    )
}

const getHeaderByName = (headerGroups: any, headerName: string) => {
    return headerGroups.length && headerGroups[0].headers ? headerGroups[0].headers.find((c: any) => c.Header == headerName) : null;
}

const shouldHideFromUser = (disallowedCustomerLevels: string[], customerLevel: string) =>
    disallowedCustomerLevels.includes(customerLevel);


const bindDependents = (column: DataTableColumn, allColumns: DataTableColumn[], refFilterQuery: any, setTempQuery: Function) => {
    if (!column) return;

    for (const filterOptions of column.FilterOptions || []) {
        if (!filterOptions || !filterOptions.dependentColumns || !filterOptions.dependentColumns.length) {
            continue;
        }

        const onChangeHandler = createOnChangeHandler(column, filterOptions, allColumns, refFilterQuery, setTempQuery);

        if (filterOptions.FilterComponent) {
            const FilterComponent = filterOptions.FilterComponent;

            // Replace the component with a new one using `cloneElement`
            filterOptions.FilterComponent = React.cloneElement(FilterComponent, {
                ...FilterComponent.props,
                onChange: onChangeHandler,
            });
        }
    }
};

const createOnChangeHandler = (column: DataTableColumn, filterOptions: FilterOptions, allColumns: DataTableColumn[], refFilterQuery: any, setTempQuery: Function) => {
    return (event) => {
        const tempQuery = { ...refFilterQuery.current };
        const columnAccessor = column?.FilterOptions[0]?.overrideFilterAccessor || column.accessor;

        if (event === undefined || event === '') {
            delete tempQuery[columnAccessor];
        } else {
            tempQuery[columnAccessor] = event;
        }

        setTempQuery(tempQuery);

        allColumns.forEach((currentColumn) => {
            if (filterOptions.dependentColumns?.includes(currentColumn.Header)) {
                handleColumnDependencies(currentColumn, allColumns, refFilterQuery, event);
            }
        });
    };
};

const handleColumnDependencies = (currentColumn: DataTableColumn, allColumns: DataTableColumn[], refFilterQuery: any, event: any) => {
    for (const column of currentColumn.FilterOptions || []) {
        if (!column || !column.onDependencyUpdated) continue;

        column.onDependencyUpdated(event);
        column.FilterComponent?.props?.reset();
        delete refFilterQuery.current[column?.overrideFilterAccessor || currentColumn.accessor];

        allColumns.forEach((dependentColumn) => {
            if (column.dependentColumns?.includes(dependentColumn.Header)) {
                handleColumnDependencies(dependentColumn, allColumns, refFilterQuery, event);
            }
        });
    }
};



