import {history} from "./history";
import {showErrorToast} from "../components/Toast";
import axiosInstance from "../../axiosInstance";


export default function exceptionHandler(response) {
    if (handleSpringSecurityTimeOut(response.data)) {
        localStorage.clear();
        return window.location.reload();
    }

    switch (response.data.status) {
        case 403:
            if (response.data.message === "Access is denied") {
                showErrorToast(response.data.message);
                return response.data;
            } else if (response.data.message === "Token Expired!") {
                axiosInstance.get(`/ajax/logout`).then((response) => {
                        localStorage.clear();
                        history.push("/login");
                        window.location.reload();
                        return null;
                    }
                ).catch(e => {
                    console.log("error", e)
                })
            } else {
                localStorage.removeItem('user');
                localStorage.clear();
                history.push("/login");
                window.location.reload();
                return null;
            }

        case 503:
            showErrorToast(response.data.message);
            return response.data;

        case 409:
            showErrorToast(response.data.message);
            return response.data;

        case 500:
            showErrorToast(response.data.message);
            return response.data;

        case 404:
            showErrorToast(response.data.message);
            return response.data;

        case 401:
            showErrorToast(response.data.message);
            return response.data;

        case 400:
            showErrorToast(response.data.message);
            return response.data;


        default:
            return response.data;
    }
}


export function responseDTOExceptionHandler(responseDTO) {

    switch (responseDTO['errorCode']) {
        case 403:
            axiosInstance.get(`/ajax/logout`).then((response) => {
                    localStorage.clear();
                    history.push("/login");
                    return null;
                }
            ).catch(e => {
                console.log("error", e)
            })
            break;

        case 409:
            return showErrorToast(responseDTO['message']);

        default:
            showErrorToast(responseDTO['message']);
    }


}

export function handleSpringSecurityTimeOut(content) {


    let doc = new DOMParser().parseFromString(content, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);

}